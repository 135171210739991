import React, { useCallback } from "react";
import { useMutation } from "@apollo/react-hooks";
// import { useSession } from "./sessionContext";
import { useGlobalState } from "../App";
// TODO unify somehow
import { TG_AUTH_MUTATION } from "../graphql/queries";
import { logout as logoutApiCall } from "../api/logout";

const TgAuthContext = React.createContext({} as any);

function AuthProvider(props: object) {
  // const session = useSession();
  const [accessToken, setAccessToken] = useGlobalState("accessToken");
  const [, setUser] = useGlobalState("user");
  const [tgLoginMutation] = useMutation(TG_AUTH_MUTATION);

  const login = useCallback(
    async (values: any) => {
      try {
        const result: any = await tgLoginMutation(values);

        if (result?.errors) {
          return Promise.reject(new Error(result.errors[0].message));
        }

        const accessToken = result.data.tg_auth.access_token;
        setAccessToken(accessToken);
      } catch (e) {
        console.error(e);
      }
    },
    [tgLoginMutation, setAccessToken]
  );

  const logout = React.useCallback(async () => {
    try {
      if (accessToken) {
        await logoutApiCall(accessToken);
        setAccessToken(undefined);
        setUser(undefined);
        localStorage.setItem("profunctor_logout", Date.now().toString());
      }
    } catch (err) {
      console.error(err);
    }
  }, [accessToken, setAccessToken, setUser]);

  const value = React.useMemo(
    () => ({
      login,
      logout,
    }),
    [login, logout]
  );

  return <TgAuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(TgAuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
