import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { split } from "apollo-link";
import { ApolloLink } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-cache-inmemory";
import { config } from "../config";

const httpurl = `${config.isDev ? "http" : "https"}://${
  config.apiHost
}/v1/graphql`;

const httpLink = createHttpLink({
  uri: httpurl,
});

const authMiddleware = (authToken: string | undefined) =>
  new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
          "X-Hasura-Role": authToken ? "user" : "anon",
        },
      });
    }

    //@ts-ignore
    return forward(operation);
  });

interface Definintion {
  kind: string;
  operation?: string;
}

const link = split(({ query }) => {
  const { kind, operation }: Definintion = getMainDefinition(query);
  return kind === "OperationDefinition" && operation === "subscription";
}, httpLink);

export const useApolloClient = (token: string | undefined) => {
  return new ApolloClient({
    link: authMiddleware(token).concat(link),
    cache: new InMemoryCache(),
  });
};
