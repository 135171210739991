import styled, { css } from "styled-components/macro";
import { AppTheme } from "../utils/themes";

type CurrentTheme = AppTheme[keyof AppTheme];
type Variant = "outline" | "primary" | "accent" | "danger";

const getButtonApperance = (theme: CurrentTheme, variant?: Variant) => {
  switch (variant) {
    case "accent":
      return css`
        background-color: ${theme.accent};
        border: 1px solid ${theme.accent};
        color: ${theme.fg};
        opacity: 0.9;

        &:hover {
          border: 1px solid ${theme.accent};
          color: ${theme.fg};
          opacity: 1;
        }
      `;

    case "outline":
      return css`
        background-color: transparent;
        border: 1px solid ${theme.gray100};
        color: ${theme.gray100};
        opacity: 0.9;

        &:hover {
          border: 1px solid ${theme.gray100};
          color: ${theme.gray100};
          opacity: 1;
        }
      `;

    case "primary":
    default:
      return css`
        background-color: ${theme.gray100};
        border: 1px solid ${theme.gray100};
        color: ${theme.bg};

        &:hover {
          border: 1px solid ${theme.gray100};
          color: ${theme.bg};
        }
      `;
  }
};

export const Button = styled.button<{ variant?: Variant; block?: boolean }>`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  color: ${(p) => (p.variant === "primary" ? p.theme.bg : p.theme.fg)};
  width: ${(p) => (p.block ? "100%" : "auto")};
  border: none;
  border-radius: ${(p) => p.theme.borderRadius};
  outline: none;
  line-height: ${(p) => p.theme.fieldHeight};
  height: ${(p) => p.theme.fieldHeight};
  padding: 0 18px;

  &:focus {
    outline-color: ${(p) => p.theme.fg};
    outline-width: 0px;
  }

  &::disabled {
    background-color: #b9b8ba;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23858585' fill-opacity='0.45' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(p) =>
      p.variant === "primary" ? p.theme.bg : p.theme.buttonColor};
  }

  ${(p) => getButtonApperance(p.theme, p.variant)}
`;

/* background-color: ${(p) => getBgColor(p.theme, p.variant)}; */
