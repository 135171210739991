export const config = {
  // in dev requrests proxied for correct cookie
  apiHost: "api.profunctor.io",
  // process.env.NODE_ENV === "development"
  //   ? "profunctor.local"
  //   : "api.profunctor.io",
  isDev: process.env.NODE_ENV !== "production",
};

//eslint-disable-next-line
process.env.NODE_ENV !== "production" ? console.log(config) : null;
