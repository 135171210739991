import styled from "styled-components";

export const FormSubtitle = styled.h5`
  font-weight: 600;
  margin-bottom: 0px;
`;

export const RowCaption = styled.p`
  font-size: ${(p) => p.theme.fontSizes[1]}px;
  margin-top: 6px;
  color: ${(p) => p.theme.textColor50};
`;

export const Title = styled.h1`
  font-size: 49px;
  font-weight: bold;
  padding: 69px 0 52px;
  margin: 0;

  @media ${(p) => p.theme.device.mobileM} {
    font-size: 36px;
    padding: 32px 0;
  }
`;

export const H1 = styled.h1`
  font-weight: bold;
`;
