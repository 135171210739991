import styled from "styled-components/macro";
import { Box } from "./Box";

export const Card = styled(Box)`
  border-radius: ${(p) => p.theme.borderRadius};
  box-shadow: ${(p) => p.theme.boxShadow};
  background-color: ${(p) => p.theme.cardBg};

  @media ${(p) => p.theme.device.tablet} {
    box-shadow: none;
    background-color: ${(p) => p.theme.bg};
  }
`;

export const ContentCard = styled(Card)`
  padding: 0 50px 50px;

  @media ${(p) => p.theme.device.mobileM} {
    padding: 0 10px 30px;
  }
`;
