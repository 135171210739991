import * as React from "react";
import styled from "styled-components";
import Header from "./Header";

export const Layout = (props: any) => {
  return (
    <Body>
      <Header />
      <Content>{props.children}</Content>
    </Body>
  );
};

export const Body = styled.div`
  background-color: ${(p) => p.theme.bg};
  color: ${(p) => p.theme.fg};
  font-family: ${(p) => p.theme.font};
  font-size: ${(p) => p.theme.fontSize};
  position: relative;
`;

const Content = styled.div`
  min-height: 100vh;
  padding-top: 100px;
`;
