const LOGOUT_MUTATION = `
  mutation {
    logout {
      affected_rows
    }
  }
`;

export async function logout(
  accessToken: string
): Promise<string | undefined | null> {
  try {
    const response = await fetch("/api/v1/graphql", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({
        query: LOGOUT_MUTATION,
      }),
    });

    const { data, errors } = await response.json();

    console.log(data);

    if (errors && errors.length >= 1) {
      // sentry
      throw new Error(errors[0].message);
    }

    return data;
  } catch (err) {
    console.log(err);
  }
}
