const REFRESH_TOKEN_MUTATION = `
  mutation {
    tg_auth_refresh_jwt_token {
      user_id,
      access_token,
    }
  }
`;

export async function getNewAccessToken(): Promise<string | undefined | null> {
  try {
    const response = await fetch("/api/v1/graphql", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        query: REFRESH_TOKEN_MUTATION,
      }),
    });

    const { data } = await response.json();

    return data.tg_auth_refresh_jwt_token
      ? data.tg_auth_refresh_jwt_token.access_token
      : null;
  } catch (err) {
    console.error(err);
  }
}
