import React from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import Form from "react-bootstrap/Form";
import styled, { withTheme } from "styled-components";
import { FieldProps } from "formik";
import { AppTheme } from "../utils/themes";
import { Label } from "./Forms";
import { FormErrorMessage } from "./FormErrorMessage";

type SelectProps = ReactSelectProps &
  FieldProps & {
    theme: AppTheme;
    label: string;
    name: string;
    defaultValue: any[];
  };

const styles = (theme: any) => ({
  multiValue: (base: any) => ({
    ...base,
    color: theme.fg,
    backgroundColor: theme.accent,
    borderRadius: "2px",
  }),
  option: (base: any) => {
    return {
      ...base,
      color: theme.fg,
      backgroundColor: theme.headerBg,
      ":hover": {
        backgroundColor: theme.accent,
      },
    };
  },
  multiValueRemove: (base: any) => ({
    ...base,
    color: theme.fg,
  }),
  singleValue: (base: any) => ({
    ...base,
    color: theme.fg,
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "#6c757d",
  }),
  container: (base: any) => ({
    ...base,
  }),
});

const selectColors = (theme: any) => ({
  ...theme,
  colors: {
    // ...theme.colors,
    // border and arrow
    neutral20: theme.tableBg,
  },
});

export const SelectBase = styled(ReactSelect)`
  .select__control {
    border: 1px solid ${(p) => p.theme.inputBg};
    border-radius: ${(p) => p.theme.borderRadius};
    background-color: ${(p) => p.theme.inputBg};
    padding: 4px 8px;
    box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
  }
`;

export const Select = withTheme((p: SelectProps) => {
  return (
    <SelectBase {...p} theme={selectColors(p.theme)} styles={styles(p.theme)} />
  );
});

export const FormSelect = withTheme((p: SelectProps) => {
  const { name } = p.field;
  const form = p.form as any;

  let errorMessage =
    typeof form.errors[name] === "string"
      ? form.errors[name]
      : form.errors[name] && form.errors[name].label;

  return (
    <>
      <Label htmlFor={p.name}>{p.label}</Label>
      <SelectBase
        {...p}
        placeholder={p.placeholder ?? "Select..."}
        classNamePrefix="select"
        theme={selectColors(p.theme)}
        styles={styles(p.theme)}
        value={p.field.value}
        name={p.field.name}
        onBlur={p.field.onBlur}
        onChange={(option: any) => {
          p.form.setFieldValue(p.field.name, option);
        }}
      />
      {!!p.form.errors[name] && p.form.touched[name] && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </>
  );
});

export const SystemSelect = styled(Form.Control)`
  border: 1px solid ${(p) => p.theme.inputBg};
  border-radius: ${(p) => p.theme.borderRadius};
  background-color: ${(p) => p.theme.inputBg};
  padding: 4px 14px;
  box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
  min-height: 46px;
  display: flex;
  width: 100%;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%239ca3af' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  appearance: none;
  color-adjust: exact;
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  -webkit-print-color-adjust: exact;
  color: ${(p) => (p.placeholderSelected ? "#6c757d" : p.theme.fg)};

  &:focus {
    outline-color: ${(p) => p.theme.fg};
    outline-width: 0px;
  }

  option {
    color: ${(p) => p.theme.fg};
    background-color: ${(p) => p.theme.headerBg};
  }
`;
