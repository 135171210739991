import gql from "graphql-tag";

const UPVOTES_BY_USER_SUBS = gql`
  subscription {
    upvotes_by_submitters(order_by: { submitted_by_username: desc }) {
      submitted_by_username
      count
    }
  }
`;

const DOWNVOTES_BY_USER_SUBS = gql`
  subscription {
    downvotes_by_submitters(order_by: { submitted_by_username: desc }) {
      submitted_by_username
      count
    }
  }
`;

const UPOVOTES_AND_DOWNVOTES = gql`
  subscription {
    downvotes_by_submitters(order_by: { count: desc }, limit: 20, offset: 0) {
      submitted_by_username
      count
    }
    upvotes_by_submitters(order_by: { count: desc }, limit: 20, offset: 0) {
      submitted_by_username
      count
    }
  }
`;

const SUBMITS_BY_USER = gql`
  subscription {
    submits_by_users {
      count
      submitted_by_username
    }
  }
`;

const ADD_JOB = gql`
  mutation Add(
    $level: String!
    $scope: String!
    $cash_min: Int!
    $cash_max: Int!
    $stack: String!
    $remote: Boolean!
    $location_country: String!
    $location_city: String!
    $location_office: String!
    $about_brief: String!
    $about_long: String!
    $company_name: String!
    $company_industry: String!
    $contact_telegram: String!
    $contact_email: String!
    $hashtags: String!
    $submitted_by: String!
  ) {
    insert_jobs(
      objects: {
        level: $level
        scope: $scope
        cash_min: $cash_min
        cash_max: $cash_max
        stack: $stack
        remote: $remote
        location_country: $location_country
        location_city: $location_city
        location_office: $location_office
        about_brief: $about_brief
        about_long: $about_long
        company_name: $company_name
        company_industry: $company_industry
        contact_telegram: $contact_telegram
        contact_email: $contact_email
        hashtags: $hashtags
        submitted_by: $submitted_by
      }
    ) {
      affected_rows
    }
  }
`;

const JOB_BY_ID = (id: string) => `
  query GetJob {
    jobs(where: { id: { _eq: "${id}" } }) {
      scope
      level
      stack
      cash_min
      cash_max
      remote
      location_office
      location_country
      location_city
      company_name
      company_industry
      about_long
      about_brief
      hashtags
      perks
      contact_telegram
      contact_email
      id
      updated_at
      created_at
      premium
    }
  }
`;

const SIMILAR_JOBS = ({
  scope,
  stack,
}: {
  stack: string;
  scope: string;
}) => gql`
  query SimilarJobs {
    jobs(
      where: { _and: [{stack: { _ilike: "%${stack}%" }}, {scope: { _ilike: "%${scope}%"}} ]}, 
      order_by: {created_at: desc_nulls_last}, 
      limit: 7
      ) {
      scope
      level
      company_name
      id
    }
  }
`;

const ADD_EMAIL_TO_WAITLIST = gql`
  mutation AddEmailToWaitlist($email: String!, $stack: String!) {
    insert_waitlist_resume(objects: { email: $email, stack: $stack }) {
      affected_rows
    }
  }
`;

const TG_AUTH_MUTATION = gql`
  mutation tg_auth($input: TGAuthRequestInput) {
    tg_auth(input: $input) {
      access_token
      user_id
    }
  }
`;

export {
  UPVOTES_BY_USER_SUBS,
  DOWNVOTES_BY_USER_SUBS,
  SUBMITS_BY_USER,
  UPOVOTES_AND_DOWNVOTES,
  ADD_JOB,
  JOB_BY_ID,
  SIMILAR_JOBS,
  ADD_EMAIL_TO_WAITLIST,
  TG_AUTH_MUTATION,
};
