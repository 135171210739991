import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${(p) => p.theme.fg};
  stroke-width: 3px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(60%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid ${(p) => p.theme.fg};
  background: ${(p) => (p.checked ? p.theme.bg : p.theme.bg)};
  border-radius: 3px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const Checkbox = ({
  className,
  checked,
  onChange,
  ...props
}: {
  className?: string;
  checked: boolean;
  onChange: any;
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} onChange={onChange} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 25 35">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export { Checkbox };
