const core = {
  font:
    '"Inter", system-ui,-apple-system,"Helvetica Neue",BlinkMacSystemFont, Arial, sans-serif',
  fontMono: '"Source Code Pro", monospace',
  borderRadius: "0.4em",
  boxShadow: "0 4px 6px hsla(0,0%,10%,.2), 0 5px 15px hsla(0,0%,10%,.1)",
  boxShadowLight: "5px 5px 5px hsla(0,0%,13%,.1), 0 5px 5px hsla(0,0%,13%,.1)",

  selection: "hsl(250,57%,88%)",

  fieldHeight: "44px",
  headerHeight: 50,

  fontSizes: [10, 12, 14, 16, 20, 24, 32],
  lineHeight: [12, 14, 16, 18, 22, 26, 36],

  // colors
  buttonColor: "#fff",

  // grays
  textColor50: "hsl(0, 0%, 62%)",
  textColor30: "hsl(0deg 0% 62% / 30%)",
  textColor20: "hsl(0deg 0% 62% / 20%)",
  // grays
  gray100: "hsla(210, 24%, 88%, .7)",

  accent: "hsl(250, 57%, 56%)",
  accentActive: "hsl(250, 46%, 48%)",
  error: "hsl(0, 100%, 46%)",

  // zIndex
  headerZindex: 10,
  modalOverlayZindex: 11,

  // media queries
  device: {
    mobileS: `(max-width: 321px)`,
    mobileM: `(max-width: 375px)`,
    mobileL: `(max-width: 425px)`,
    tablet: `(max-width: 768px)`,
    laptop: `(max-width: 1024px)`,
    laptopL: `(max-width: 1440px)`,
    desktop: `(max-width: 1920px)`,
    desktopL: `(max-width: 2560px)`,
  },
};

const dark = {
  ...core,
  bg: "#3a3f45",
  fg: "#eaeaea",
  inputBg: "hsl(213, 9%, 19%)",
  cardBg: "#40454d",
  tableBg: "#686868",

  headerBg: "#2f3337",
  dimmedFg: "hsla(210, 3%, 18%, 0.5)",

  selectedBg: "hsla(210, 3%, 18%, 0.26)",

  buttonBg: "#fff",
};

const light = {
  ...core,
  bg: "#fdf6e2",
  fg: "#6b615b",
  inputBg: "#fff3d0",

  borderWeak: "rgba(135,135,135, 1.00)",
  tableBg: "#686868",
  headerBg: "#fff3d0",
  buttonBg: "#2f3337",
  dimmedFg: "#2f3337",
};

export const theme = {
  light,
  dark,
};

export type AppTheme = typeof theme;
