import * as React from "react";
import styled from "styled-components/macro";
import { Flex } from "reflexbox";
import { useMediaQuery } from "react-responsive";

import { useGlobalState } from "../App";
import { Link, LinkHref, NavLink, TextLink } from "./Links";
import Container from "react-bootstrap/Container";
import { TgAuth } from "./TgAuth";
import { SandwichIcon } from "./Sandwich";
import { useSession } from "../contexts/sessionContext";

const Header = () => {
  const [user] = useGlobalState("user");
  const { authInProgress } = useSession();
  const [open, setOpen] = React.useState(false);
  const isAuthorized = user && user.id;

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  const ProfileOrLoginButton = () => {
    if (authInProgress) {
      return (
        <NavLink
          onClick={(e) => setOpen(false)}
          className="nav-link"
          to="/me"
          // activeClassName="active"
        >
          Loading...
        </NavLink>
      );
    }

    return isAuthorized ? (
      <NavLink
        onClick={(e) => setOpen(false)}
        className="nav-link"
        to="/me"
        // activeClassName="active"
      >
        Profile
      </NavLink>
    ) : (
      <TgAuth component={<TextLink className="nav-link">Login</TextLink>} />
    );
  };

  const MenuItems = ({ expanded }: { expanded: boolean }) => (
    <ManuItemsWrapper expanded={expanded}>
      <NavLink className="nav-link" onClick={() => setOpen(false)} to="/search">
        Search memes
      </NavLink>
      <NavLink className="nav-link" onClick={(e) => setOpen(false)} to="/">
        Job Board
      </NavLink>
      <NavLink
        onClick={(e) => setOpen(false)}
        className="nav-link"
        to="/jobs/add"
      >
        Add Job
      </NavLink>
      <ProfileOrLoginButton />
    </ManuItemsWrapper>
  );

  return (
    <HeaderWrapper>
      <Wrap fluid>
        <Logo>
          <Link to="/">
            profunctor v2.0.0 <br />
            public
          </Link>
        </Logo>

        <Flex>
          {isDesktopOrLaptop && !open && <MenuItems expanded={open} />}
          {!isDesktopOrLaptop && <SandwichIcon open={open} setOpen={setOpen} />}
        </Flex>
      </Wrap>
      {open && <MenuItems expanded={open} />}
    </HeaderWrapper>
  );
};
export default Header;

const HeaderWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: ${(p) => p.theme.headerZindex};
  background-color: ${(p) => p.theme.cardBg};
  box-shadow: ${(p) => p.theme.boxShadow};
`;

const Wrap = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  padding: 20px 15px;
`;

const Logo = styled.div`
  font-family: ${(p) => p.theme.fontMono};
  font-size: 11px;
  &:after {
    content: " β";
  }

  a:hover {
    text-decoration: none;
  }
`;

const ManuItemsWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: ${({ expanded }) => (expanded ? "column" : "row")};
  justify-content: space-around;
  top: 0;
  left: 0;

  > a {
    font-size: ${({ expanded }) => (expanded ? "18px" : "14px")};
    padding: ${({ expanded }) => (expanded ? "15px 20px" : "10px")};
    border-radius: ${({ theme }) => theme.borderRadius};
  }

  > a:hover {
    transition: all ease-in 0.3s;
    background-color: ${({ theme }) => theme.dimmedFg};
  }

  .nav-link.active {
    background-color: ${({ theme }) => theme.dimmedFg};
  }
`;
