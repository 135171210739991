import React from "react";
import { FiMoon, FiSun } from "react-icons/fi";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const DarkMode = styled(FiMoon)`
  display: block;

  svg {
    stroke: ${(p) => p.theme.fg};
  }
`;

const DefaultMode = styled(FiSun)`
  display: block;
`;

interface Props {
  toggleTheme: (s: string) => void;
  themeName: string;
}

export const ThemeToggler = ({ toggleTheme, themeName }: Props) => {
  return (
    <Button
      variant={themeName === "dark" ? "outline-light" : "outline-dark"}
      onClick={() => toggleTheme(themeName === "dark" ? "light" : "dark")}
    >
      {themeName === "dark" ? (
        <DefaultMode title="Switch to light mode" />
      ) : (
        <DarkMode title="Switch to dark mode" />
      )}
    </Button>
  );
};

// const Button = styled.button`
//   background: transparent;
//   font-size: 17px;
//   color: ${p => p.theme.fg};
//   display: flex;
//   justify-content: space-around;
//   border: none;

//   /* &:focus {
//     outline: 2px solid ${p => p.theme.accentColor};
//   } */

//   &:hover {
//     cursor: pointer;
//   }

//   @media screen and (max-width: 600px) {
//     font-size: 14px;
//   }
// `;
