import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
  createContext,
} from "react";
import jwtDecode from "jwt-decode";
import { getNewAccessToken } from "../api/getNewAccessToken";
import { getCurrentUser } from "../api/getCurrentUser";
import { useGlobalState } from "../App";
import { Loading } from "../ui/Loading";

const SessionContext = createContext({} as any);

export function SessionProvider(props: any) {
  const [accessToken, setAccessToken] = useGlobalState("accessToken");
  const [user, setUser] = useGlobalState("user");
  const [authInProgress, setAuthInProgress] = useState(true);

  useEffect(() => {
    const fetchAccessToken = async () => {
      setAuthInProgress(true);
      try {
        const token = await getNewAccessToken();
        if (!!token) {
          console.log("got new access token, setting in to state");
          setAccessToken(token);
        }
        setAuthInProgress(false);
      } catch (err) {
        setAuthInProgress(false);
        console.error(err);
      }
    };

    fetchAccessToken();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchUser = async (token: string) => {
      try {
        console.log("token exists, trying to get user");
        const newUser = await getCurrentUser(token);
        setUser(newUser);
        setAuthInProgress(false);
      } catch (err) {
        setAuthInProgress(false);
        console.error(err);
      }
    };

    if (accessToken) {
      fetchUser(accessToken);
    }
    // eslint-disable-next-line
  }, [accessToken]);

  // const _updateAccessToken = React.useCallback(async () => {
  //   if (!refreshToken) {
  //     setIsPending(false);
  //     return;
  //   }

  //   const decoded: any = jwtDecode(refreshToken);

  //   if (decoded.exp < Date.now() / 1000) {
  //     console.log("Session expired");

  //     setIsPending(false);
  //     return;
  //   }

  //   if (!accessToken) {
  //     return null;
  //   }

  //   const newAccessToken = await getNewAccessToken();
  //   newAccessToken && setAccessToken(newAccessToken);
  //   setIsPending(false);
  // }, [refreshToken]);

  // Auto update access-token
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const decoded: any = jwtDecode(accessToken);
    const renewBefore = 60;
    const expireIn = decoded.exp - renewBefore - Date.now() / 1000;

    console.log(`token expires in ${expireIn / 86400} days`);

    timeoutIdRef.current = setTimeout(getNewAccessToken, expireIn * 1000);

    if (expireIn <= 0) {
      getNewAccessToken()
        .then((token) => {
          if (!!token) {
            setAccessToken(token);
          }
        })
        .catch((e) => {
          setAuthInProgress(false);
          console.error(e);
        });
    }

    return cancel;
  }, [cancel, setAuthInProgress, setAccessToken, accessToken]);

  const value = useMemo(
    () => ({
      currentUser: user,
      accessToken,
      setAccessToken,
      authInProgress,
    }),
    [user, accessToken, setAccessToken, authInProgress]
  );

  if (authInProgress) {
    return <Loading />;
  }

  return <SessionContext.Provider value={value} {...props} />;
}

export function useSession() {
  const context = React.useContext(SessionContext);

  if (typeof context === "undefined") {
    throw new Error(`useSession must be used within a SessionProvider`);
  }

  return context;
}
