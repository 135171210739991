import styled from "styled-components";

export const FormErrorMessage = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.error};
  width: 100%;
  margin-top: 0.25rem;
  &:before {
    content: "❌ ";
    font-size: 10px;
  }
`;
