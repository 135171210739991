import { User } from "../types/react-app-env";

const ME_QUERY = `
  query Me {
    auth_me {
      id
      tg_id
      username
      first_name
      last_name
      photo_url
      user_resume {
        scope
        level
        stack
      }
    }
  }
`;

export async function getCurrentUser(
  accessToken: string
): Promise<User | undefined> {
  try {
    const response = await fetch("api/v1/graphql", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken,
        "x-hasura-role": "user",
      },
      body: JSON.stringify({
        query: ME_QUERY,
      }),
    });

    const { data, errors } = await response.json();

    console.log(data);

    if (errors && errors.length >= 1) {
      // sentry
      throw new Error(errors[0].message);
    }

    // query result, might be null
    return data.auth_me;
  } catch (err) {
    console.log(err);
  }
}
