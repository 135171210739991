import { AppTheme } from "./themes";
import { createGlobalStyle } from "styled-components";

export const makeThemedGlobalStyle = (
  theme: AppTheme,
  themeName: keyof AppTheme
) => createGlobalStyle`
    html, body, #root {
      background-color: ${theme[themeName].bg};
      color:${theme[themeName].fg};
      font-size: 1rem;
    }

    * { 
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-tap-highlight-color: transparent;
    }

    a { 
      text-decoration: none!important; 
      color:${theme[themeName].accent};

      &:hover {
        color:${theme[themeName].accent};
      }
    }

    *::selection { background-color: ${theme[themeName].selection}; }

    ul { 
      padding-inline-start: 0;
      margin-bottom: 0;
    }
  `;
