import { Link as RouterLink } from "react-router-dom";
import { NavLink as BSNavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const LinkStyle = css`
  color: ${(p) => p.theme.fg};
  opacity: 0.7;

  .active {
    opacity: 1;
  }

  &:hover {
    color: ${(p) => p.theme.fg};
    text-decoration: none;
    opacity: 1;
  }
`;

export const NavLink = styled(BSNavLink)`
  ${LinkStyle}
  padding: 0;
  margin-left: 6px;

  @media ${(p) => p.theme.device.mobileM} {
    margin-left: 0px;
  }
`;

export const LinkHref = styled.a`
  ${LinkStyle}
  padding: 0;
`;

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.fg};

  &:hover {
    color: ${(p) => p.theme.fg};
    text-decoration: none;
    opacity: 1;
  }
`;

export const ButtonLink = styled(RouterLink)`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  background-color: ${(p) => p.theme.accent};
  color: ${(p) => p.theme.fg};
  width: auto;
  border: none;
  border-radius: ${(p) => p.theme.borderRadius};
  outline: none;
  padding: 14px 15px;
  margin-left: 40px;

  &:hover,
  &:active,
  &:focus {
    border: none;
    color: ${(p) => p.theme.buttonColor};
    background-color: ${(p) => p.theme.accent};
  }
`;

export const TextLink = styled.span`
  ${LinkStyle}
  font-size: 14px;
  line-height: 23px;

  @media ${(p) => p.theme.device.mobileM} {
    font-size: 18px;
    padding: 15px 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;
