import * as React from "react";
import styled from "styled-components";

export const Sandwich = styled.button<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  opacity: 0.7;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 3px;
    border-radius: 1px;
    background: ${({ theme }) => theme.fg};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transition: 0.2s;
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const SandwichIcon = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  return (
    <Sandwich open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </Sandwich>
  );
};
