import * as React from "react";
import styled from "styled-components/macro";
import { PortalWithState } from "react-portal";
import { useGlobalState } from "../App";

import { Button } from "../ui";

export const TGAuthModal = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
  component?: any;
}) => {
  const [user] = useGlobalState("user");

  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ openPortal, closePortal, isOpen, portal }: any) => (
        <>
          {user && user.id && closePortal()}
          {rest.component ? (
            React.cloneElement(rest.component, {
              onClick: (e: any) => {
                e.preventDefault();
                openPortal(e);
              },
            })
          ) : (
            <Button
              onClick={(e) => {
                openPortal(e);
              }}
            >
              Telegram login
            </Button>
          )}
          {portal(
            <>
              <Overlay
                onClick={(e) => {
                  closePortal(e);
                }}
              >
                <Dialog>{children}</Dialog>
              </Overlay>
            </>
          )}
        </>
      )}
    </PortalWithState>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${(p) => p.theme.modalOverlayZindex};
  background: rgba(0, 0, 0, 0.33);
`;

const Dialog = styled.div`
  border-radius: ${(p) => p.theme.borderRadius};
  padding: 20px 30px;
  width: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(p) => p.theme.cardBg};
  z-index: ${(p) => p.theme.modalOverlayZindex + 1};
  height: 210px;

  @media ${(p) => p.theme.device.mobileL} {
    width: 300px;
    height: 210px;
  }
`;
