import * as React from "react";
import { useAuth } from "../contexts/authContext";
import TelegramLoginButton from "react-telegram-login";
import { TGAuthModal } from "./TGAuthModal";
import { Flex } from "reflexbox";
import { Loading } from "./Loading";
import styled from "styled-components/macro";

export const TgAuth = ({ component }: { component?: React.ReactElement }) => {
  const auth = useAuth();
  const [loading, setLoading] = React.useState(false);

  // TODO telegram response type
  const handleTelegramResponse = async (tgPayload: any) => {
    try {
      auth.login({ variables: { input: tgPayload } });
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <TGAuthModal component={component}>
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <LoginBox>
            <h4>Login</h4>
            <p>
              We do not have access to your phone number, and you can opt out
              any time
            </p>
          </LoginBox>
          <Flex
            onClick={() => setLoading(true)}
            width="100%"
            justifyContent="space-around"
          >
            <TelegramLoginButton
              type="button"
              dataOnauth={handleTelegramResponse}
              botName="pfoptix_bot"
            />
          </Flex>
        </Flex>
      </TGAuthModal>
    </>
  );
};

const LoginBox = styled.div`
  margin-bottom: 15px;

  h4 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 18px;
  }

  p {
    display: block;
    max-width: 250px;
    margin: 0 auto;
    text-align: center;
    font-size: ${(p) => p.theme.fontSizes[1]}px;
    margin-top: 6px;
    color: ${(p) => p.theme.textColor50};
  }
`;
