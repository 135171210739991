import "@rainbow-me/rainbowkit/styles.css";

import * as React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createGlobalState } from "react-hooks-global-state";
import { ThemeProvider } from "styled-components";
import { theme, AppTheme } from "./utils/themes";
import ReactGA from "react-ga";
import Loadable from "react-loadable";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import * as Sentry from "@sentry/browser";

import { useApolloClient } from "./graphql/apollo";

import { Layout } from "./ui";

import { makeThemedGlobalStyle } from "./utils/globalStyle";
import { AuthProvider } from "./contexts/authContext";
import { SessionProvider } from "./contexts/sessionContext";
import { AppState } from "./types/react-app-env";
import { Loading } from "./ui/";

import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

const { chains, provider } = configureChains(
  [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Profunctor Web3",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const Ladder = Loadable({
  loader: () => import("./pages/Ladder"),
  loading: Loading,
});
const Memes = Loadable({
  loader: () => import("./pages/Search"),
  loading: Loading,
});
const JobTable = Loadable({
  loader: () => import("./pages/Jobs/JobTable"),
  loading: Loading,
});
const Add = Loadable({
  loader: () => import("./pages/Jobs/Add/Add"),
  loading: Loading,
});
const EditJob = Loadable({
  loader: () => import("./pages/Jobs/Edit"),
  loading: Loading,
});
const Detailed = Loadable({
  loader: () => import("./pages/Jobs/Detailed/Detailed"),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import("./pages/Profile"),
  loading: Loading,
});

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://ab8f6fea317d45b2872c25a47706053b@o380632.ingest.sentry.io/5206739",
  });
}

// const history = createBrowserHistory();

type themeNameType = keyof AppTheme;

const initialState = {
  theme: "dark" as themeNameType,
  accessToken: undefined,
  refreshToken: undefined,
  user: undefined,
};
const { useGlobalState } = createGlobalState<AppState>(initialState);

const App = () => {
  const GlobalStyle = makeThemedGlobalStyle(theme, initialState.theme);

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("UA-143012488-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  const [accessToken] = useGlobalState("accessToken");
  const [refreshToken] = useGlobalState("refreshToken");
  const client = useApolloClient(accessToken);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          accentColor: "#7b3fe4",
          accentColorForeground: "hsl(250,57%,88%)",
          borderRadius: "small",
          fontStack: "system",
          overlayBlur: "small",
        })}
      >
        <ThemeProvider theme={theme[initialState.theme]}>
          <Router>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <ApolloProvider client={client as any}>
                <SessionProvider
                  accessToken={accessToken}
                  refreshToken={refreshToken}
                >
                  <AuthProvider>
                    <GlobalStyle />
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Layout>
                            {" "}
                            <JobTable />
                          </Layout>
                        }
                      />
                      <Route path="/search" element={<Memes />} />
                      <Route
                        path="/rating"
                        element={
                          <Layout>
                            {" "}
                            <Ladder />{" "}
                          </Layout>
                        }
                      />
                      <Route
                        path="/jobs"
                        element={
                          <Layout>
                            <JobTable />{" "}
                          </Layout>
                        }
                      />
                      <Route
                        path="/jobs/add"
                        element={
                          <Layout>
                            <Add />{" "}
                          </Layout>
                        }
                      />
                      <Route
                        path="/jobs/:id"
                        element={
                          <Layout>
                            <Detailed />
                          </Layout>
                        }
                      />
                      <Route
                        path="/jobs/:id/edit"
                        element={
                          <Layout>
                            <EditJob />
                          </Layout>
                        }
                      />
                      <Route
                        path="/me"
                        element={
                          <Layout>
                            <Profile />{" "}
                          </Layout>
                        }
                      />
                    </Routes>
                  </AuthProvider>
                </SessionProvider>
              </ApolloProvider>
            </QueryParamProvider>
          </Router>
        </ThemeProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

const AppWithState = () => <App />;
export default AppWithState;
export { useGlobalState };
