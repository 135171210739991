import * as React from "react";
import styled from "styled-components/macro";
import BootstrapFormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";
import TextareaAutosize from "react-textarea-autosize";
import { FieldProps } from "formik/dist/Field";
import { FormErrorMessage } from "./FormErrorMessage";

export const Input = styled<any>(BootstrapFormControl)`
  height: 46px;
  width: 100%;
  color: ${(p) => p.theme.fg};
  background-color: ${(p) => p.theme.inputBg};
  border: 1px solid ${(p) => p.theme.inputBg};
  outline: none;
  box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
  border-radius: ${(p) => p.theme.borderRadius};

  &:focus,
  &:active,
  &:read-only {
    outline: none;
    color: ${(p) => p.theme.fg};
    border-color: ${(p) => p.theme.dimmedFg};
    background-color: ${(p) => p.theme.inputBg};
    box-shadow: none;
  }
`;

export const FormInput = (p: FieldProps) => {
  const { name } = p.field;
  return (
    <>
      <Input
        name={p.field.name}
        value={p.field.value}
        onChange={p.field.onChange}
      />
      {!!p.form.errors[name] && p.form.touched[name] && (
        <FormErrorMessage>{p.form.errors[name]}</FormErrorMessage>
      )}
    </>
  );
};

export const Textarea = styled<any>(TextareaAutosize)`
  width: 100%;
  background-color: ${(p) => p.theme.inputBg};
  border: 1px solid ${(p) => p.theme.inputBg};
  color: ${(p) => p.theme.fg};
  border-radius: 3px;
  padding: 12px;
  outline: none;
  box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
  border-radius: ${(p) => p.theme.borderRadius};

  &:focus,
  &:active {
    border-color: ${(p) => p.theme.dimmedFg};
    background-color: ${(p) => p.theme.inputBg};
    outline-color: transparent;
    outline: none;
  }
`;

export const FormTextarea = (p: FieldProps) => {
  const { name } = p.field;
  const form = p.form as any;
  let errorMessage =
    typeof form.errors[name] === "string"
      ? form.errors[name]
      : form.errors[name] && form.errors[name].label;
  return (
    <>
      <Textarea
        {...p}
        name={p.field.name}
        value={p.field.value}
        onChange={p.field.onChange}
      />
      {!!p.form.errors[name] && p.form.touched[name] && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </>
  );
};

export const Label = styled.label`
  margin-bottom: 9px;
  font-size: 10px;
  /* line-height: 10px; */
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const FormRow = styled(Row)`
  margin-bottom: 64px;
`;
