import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import gray from "./animations/face_id_gray.json";
// import blue from "./animations/face_id_blue.json";

export const Loading = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: gray,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Plate>
      <div>
        <Lottie
          options={defaultOptions}
          height={40}
          width={40}
          ariaLabel="Loading..."
          isStopped={false}
          isPaused={false}
        />
        <p>Haha! We have face ID...</p>
      </div>
    </Plate>
  );
};

const Plate = styled.div`
  background-color: ${(p: any) => p.theme.bg};
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & div p {
    margin-top: 10px;
    font-family: ${(p) => p.theme.fontMono};
    color: ${(p: any) => p.theme.fg};
    font-size: 13px;
  }
`;
